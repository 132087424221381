import React, { ReactNode, memo, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Config, usePopperTooltip } from 'react-popper-tooltip'

import { Variant, Width } from '../../../themes/types'
import { StyledArrow, StyledTooltip, StyledTrigger } from './Tooltip.styles'

export type ExtendedVariant = Variant | 'black' | 'white'

export interface CustomTooltipContentProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  closeOnOutsideClick?: boolean
  fallbackPlacements?: Config['placement'][]
  hideArrow?: boolean
  maxWidth?: Width
  offset?: [number, number]
  onVisibilityChange?: Config['onVisibleChange']
  open?: boolean
  placement?: Config['placement']
  strategy?: 'absolute' | 'fixed'
  tooltip: React.ReactNode
  trigger?: Config['trigger']
  variant?: ExtendedVariant
  withPortal?: boolean
}

const TooltipContent = ({
  'data-e2e': dataE2e,
  children,
  className,
  closeOnOutsideClick = true,
  fallbackPlacements,
  hideArrow = false,
  maxWidth = '450px',
  offset = [0, 7],
  onVisibilityChange,
  open,
  placement: customPlacement = 'auto',
  strategy = 'absolute',
  tooltip,
  trigger = 'hover',
  variant = 'white',
  withPortal = true,
}: CustomTooltipContentProps) => {
  const [isOpened, setIsOpened] = useState<boolean>()
  const modifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset,
      },
    },
    ...(fallbackPlacements
      ? [
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements,
            },
          },
        ]
      : []),
  ]

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip(
    {
      closeOnOutsideClick,
      defaultVisible: false,
      onVisibleChange: onVisibilityChange,
      trigger,
      visible: isOpened,
    },
    {
      placement: customPlacement,
      modifiers,
      strategy,
    }
  )

  // Handle click outside
  useEffect(() => {
    if (typeof open !== 'boolean' || !closeOnOutsideClick) return undefined

    const handleClickOutside: EventListener = event => {
      const { target } = event
      if (target instanceof Node) {
        if (
          tooltipRef != null &&
          triggerRef != null &&
          !tooltipRef.contains(target) &&
          !triggerRef.contains(target)
        ) {
          setIsOpened(false)
        }
      }
    }
    window.addEventListener('mousedown', handleClickOutside)

    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [tooltipRef, triggerRef])

  useEffect(() => {
    setIsOpened(open)
  }, [open])

  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = useCallback(e => {
    e.stopPropagation()
  }, [])

  const renderTooltip = () => (
    <StyledTooltip
      {...getTooltipProps({ className })}
      data-e2e={dataE2e}
      maxWidth={maxWidth}
      onMouseDown={stopPropagation}
      ref={setTooltipRef}
      variant={variant}
    >
      {!hideArrow && (
        <StyledArrow
          {...getArrowProps({
            className: 'tooltip-arrow',
            'data-placement': customPlacement,
          })}
          variant={variant}
        />
      )}
      {tooltip}
    </StyledTooltip>
  )

  return (
    <>
      <StyledTrigger ref={setTriggerRef}>{children}</StyledTrigger>
      {visible &&
        (withPortal ? ReactDOM.createPortal(renderTooltip(), document.body) : renderTooltip())}
    </>
  )
}

export default memo(TooltipContent)
