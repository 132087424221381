import { invert } from 'polished'
import styled from 'styled-components'

import { AdvancedColors, ColorShade, Variant, Width } from '../../../themes'
import { getForegroundColor } from '../../../themes/helpers'
import { ExtendedVariant } from './TooltipContent'

const getBackgroundColor = (colors: AdvancedColors, variant: Variant, shade: ColorShade) => {
  if (variant === 'black') return '#262626'
  if (variant === 'white') return variant

  return colors[variant][shade]
}

export const StyledTrigger = styled.div`
  display: inline-block;
  max-width: 100%;
`

export const StyledTooltip = styled.div<{ variant: ExtendedVariant; maxWidth: Width }>`
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
  z-index: 10001;
  margin: 0;
  max-width: ${props => props.maxWidth};
  padding: ${props => props.theme.spacingsInsetSquish.small};
  background-color: ${props => getBackgroundColor(props.theme.colors, props.variant, 'base')};
  border-color: ${props => getBackgroundColor(props.theme.colors, props.variant, 'dark')};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  color: ${props =>
    props.variant === 'black' || props.variant === 'white'
      ? invert(props.variant)
      : getForegroundColor(props.variant, props.theme)};

  &[data-popper-reference-hidden='true'],
  &[data-popper-escaped='true'] {
    visibility: hidden;
  }
`

export const StyledArrow = styled.div<{ variant: ExtendedVariant }>`
  &,
  &::before,
  &::after {
    position: absolute;
    width: 8px;
    height: 8px;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    background-color: ${props => getBackgroundColor(props.theme.colors, props.variant, 'dark')};
  }

  &::after {
    content: '';
    transform: rotate(45deg);
    background-color: ${props => getBackgroundColor(props.theme.colors, props.variant, 'base')};
  }

  [data-popper-placement^='top'] > &.tooltip-arrow {
    bottom: -4px;
    &::before {
      top: 1px;
    }
  }

  [data-popper-placement^='bottom'] > &.tooltip-arrow {
    top: -4px;
    &::before {
      bottom: 1px;
    }
  }

  [data-popper-placement^='left'] > &.tooltip-arrow {
    right: -4px;
    &::before {
      left: 1px;
    }
  }

  [data-popper-placement^='right'] > &.tooltip-arrow {
    left: -4px;
    &::before {
      right: 1px;
    }
  }
`
