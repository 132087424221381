import React, { memo } from 'react'

import TooltipContent, { CustomTooltipContentProps } from './TooltipContent'

export type CustomTooltipProps = CustomTooltipContentProps & {
  off?: boolean
}

const Tooltip = ({ off = false, children, ...rest }: CustomTooltipProps) =>
  off ? children : <TooltipContent {...rest}>{children}</TooltipContent>

export default memo(Tooltip)
